import React from 'react'
import Slider from 'react-slick'
import BsArrowRight from '@meronex/icons/bs/BsArrowRight'
import BsArrowLeft from '@meronex/icons/bs/BsArrowLeft'
import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Link } from 'gatsby'

dayjs.extend(relativeTime)

const BreakingNewsCarousel = ({ data }) => {
  const settings = {
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 2,
    // lazyLoad: true,
    rows: 1,
    arrows: true,
    slidesPerRow: 1,
    speed: 700,
    nextArrow: <BsArrowRight />,
    prevArrow: <BsArrowLeft />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 1,
          arrows: false,
          accessibility: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          rows: 1,
          slidesToScroll: 1,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          slidesPerRow: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  }

  return (
    <div className="mx-3 my-5 pb-5">
      <h2 className="breaking-heading inline-block text-gray-50 font-semibold px-3 py-1 tracking-wide mt-9 mb-6 ml-16">
        BREAKING
      </h2>
      <Slider {...settings} className="mx-12">
        {data.map((i) => {
          console.log(i)
          return (
            <div
              className="w-1/6 h-40 sm:h-36 md:h-28 border-r-2 border-gray-300"
              key={i.node.id}
            >
              <div className="ml-5 mr-5">
                <p className="breaking-news-time">
                  {dayjs().to(dayjs(i.node.postDate))}
                </p>
                <Link
                  to={`/${i.node.node_locale}/posts/${i.node.slug}`}
                  className="breaking-news-link hover:underline"
                >
                  {i.node.postTitle}
                </Link>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default BreakingNewsCarousel
