import React, { useEffect, useState } from 'react'
import firebase from 'gatsby-plugin-firebase'
import CountUp from 'react-countup'
import onlineViews from '../../lib/online-views'

const OnlineViewCounter = ({ id }) => {
  const [onlineViewCount, setOnlineViewCount] = useState('')

  useEffect(() => {
    // 1 is displayed for a split second and then the correct count
    // This is a workaround
    const onViews = (newViews) => {
      setOnlineViewCount(newViews.val() === 1 ? 0 : newViews.val())
    }

    onlineViews(id)

    firebase.database().ref(`/online`).child(id).on(`value`, onViews)

    return () => {
      if (firebase.database()) {
        firebase.database().ref(`/online`).child(id).off(`value`, onViews)
      }
    }
  }, [id])

  // let fakeCountBigNum = 106265

  return (
    <div
      className="text-forbes-dark-gray text-xs font-sans font-normal"
      style={{
        marginTop: '16px',
      }}
    >
      <CountUp
        start={0}
        end={Number(onlineViewCount)} // if you want to test it with a big number use fake big num variable on line 27
        duration={3}
        separator=","
        decimal=","
        delay={0}
      >
        {({ countUpRef }) => (
          <>
            <div>
              <span
                style={{
                  fontFamily: 'Monaco, Courier, sans-serif',
                  fontSize: '38px',
                  fontWeight: 'bold',
                  color: '#737373',
                  letterSpacing: '3px',
                  marginRight: '15px',
                }}
                ref={countUpRef}
              ></span>
              <span
                style={{
                  fontFamily: 'Work Sans',
                  fontSize: '16px',
                  color: '#737373',
                }}
              >
                READERS
              </span>
            </div>
          </>
        )}
      </CountUp>
    </div>
  )
}

export default OnlineViewCounter
