import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import OnlineViewCounter from '../OnlineViewCounter'

const SidebarWithArticles = () => {
  return (
    <StaticQuery
      query={graphql`
        query articleQuery {
          allContentfulPosts(
            filter: { node_locale: { eq: "en-US" } }
            limit: 20
          ) {
            edges {
              node {
                postTitle
                slug
                id
                node_locale
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <div className="right-sidebar-with-articles w-12/12 lg:w-4/12 flex flex-wrap content-start mt-10 lg:mt-0">
            {/* <div className="flex w-full h-20 mt-5">
              <div className="readership w-1/2 text-4xl">
                <div className="readership-icon flex justify-center items-center">
                  <FaEye className="" />
                  <span className="text-3xl">86,824</span>
                </div>
                <p className="text-base text-center">Audience Readership</p>
              </div>
              <div className="followers w-1/2 text-4xl">
                <div className="readership-icon flex justify-center items-center">
                  <BiShareAlt className="" />
                  <span className="text-3xl">86,824</span>
                </div>
                <p className="text-base text-center">Social Followers</p>
              </div>
            </div> */}

            <div className="popular-articles w-full my-1">
              <OnlineViewCounter id={'Home'} />

              {data.allContentfulPosts.edges.map((i) => (
                <h6 className="font-semibold" key={i.node.id}>
                  <Link to={`/${i.node.node_locale}/posts/${i.node.slug}`}>
                    {i.node.postTitle}
                  </Link>
                </h6>
              ))}
            </div>
          </div>
        </>
      )}
    />
  )
}

export default SidebarWithArticles
