import React from 'react'
import { Link } from 'gatsby'
import Time from '../Time'
import { GatsbyImage } from 'gatsby-plugin-image'
import SidebarWithArticles from '../SidebarWithArticles'

const CategoryRollWith20ArticlesSidebar = ({ node }) => {
  return (
    <div>
      <section id="category" className="mt-10 w-full">
        <div
          className={`${`border-` + node.categoryColor} ${
            `text-` + node.categoryColor
          } flex flex-nowrap py-4 font-sans font-semibold text-2xl text-left border-t-4 border-b-2 mt-4 mb-2 mx-3`}
        >
          <Link to={`/${node.node_locale}/categories/${node.categorySlug}`}>
            <h1 className="">{node.category}</h1>
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            className="w-5 mx-2 inline-flex"
          >
            <path
              transform="rotate(-180 8.964 11)"
              fill="currentColor"
              d="M1 10h16v2H1z"
            />
            <path
              transform="rotate(134.999 14.965 13.124)"
              fill="currentColor"
              d="M11 12.1h8v2h-8z"
            />
            <path
              transform="rotate(-134.999 14.965 8.877)"
              fill="currentColor"
              d="M11 7.9h8v2h-8z"
            />
          </svg>
        </div>

        <div className="articles-and-sidebar flex flex-col lg:flex lg:flex-row">
          <div className="top-roll-parent bg-forbes-site-white w-12/12 lg:w-8/12">
            {node &&
              node.posts.slice(0, 3).map((p, i) => (
                <div className="m-3" key={i}>
                  <Link to={`/${p.node_locale}/posts/${p.slug}`}>
                    <figure className="mb-4 relative">
                      {p?.postImage ? (
                        <GatsbyImage image={p?.postImage?.gatsbyImageData} />
                      ) : null}
                      <div
                        className={`${
                          `bg-` + node.categoryColor
                        } label-on-first-child-article text-white absolute z-10 text-center uppercase font-bold p-auto block font-xs px-3 py-2`}
                      >
                        {p?.postSubCategories?.subCategories}
                      </div>
                    </figure>
                  </Link>
                  <h3 className="hover:underline tracking-wide">
                    <Link
                      className="BlogRollTitle"
                      to={`/${p.node_locale}/posts/${p.slug}`}
                    >
                      {p.postTitle}
                    </Link>
                  </h3>
                  <div className="text-forbes-type-black text-xs mt-4 font-sans font-normal">
                    <span className="b_r">
                      <Time pubdate date={p.postDate} />
                    </span>
                    By{' '}
                    <span className="font-sans text-forbes-type-black font-bold px-1">
                      {p.postAuthor.fullName} {''}
                    </span>
                    <span className="text-forbes-type-black text-xs font-sans font-normal">
                      {p.postAuthor.position}
                    </span>
                  </div>
                  <p className="BlogRollPostContent mt-3">
                    {p.postDescription}
                  </p>
                  <Link to={`/${p.node_locale}/posts/${p.slug}`}>
                    <p className="text-forbes-read-more text-md underline mt-3 font-geo font-normal">
                      Read more
                    </p>
                  </Link>
                </div>
              ))}
          </div>
          <SidebarWithArticles />
        </div>
      </section>
    </div>
  )
}

export default CategoryRollWith20ArticlesSidebar
