import firebase from 'gatsby-plugin-firebase'

const onlineViews = async (id) => {
  const ref = firebase.database().ref(`/online`).child(id)
  ref.transaction((currentViews) => {
    return currentViews + 1
  })
}

export default onlineViews
