import React from 'react'
import * as PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CategoryRoll from '../components/CategoryRoll'
import CategoryRollWith20ArticlesSidebar from '../components/CategoryRollWith20ArticlesSidebar'
import BreakingNewsCarousel from '../components/BreakingNewsCarousel'
import OnlineViewCounter from '../components/OnlineViewCounter'

const propTypes = {
  data: PropTypes.object.isRequired,
}

class IndexPage extends React.Component {
  render() {
    const usTopBreaking = this.props.data.usTopBreaking.edges
    const usTopCategory = this.props.data.usTop.edges
    const usBottomCategory = this.props.data.usBottom.edges
    const breakingSlick = this.props.data.breakingQuery.edges

    return (
      <Layout data={this.props.data} location={this.props.location}>
        <div className="bg-forbes-site-white">
          {/* <AdPremium /> */}
          <div className="mt-16 xl:mt-4">
            <BreakingNewsCarousel data={breakingSlick} />
          </div>

          <div className="flex lg:w-12/12">
            {usTopBreaking.slice(0, 1).map(({ node }, i) => (
              <>
                <CategoryRollWith20ArticlesSidebar node={node} key={i} />
              </>
            ))}
            <div className="lg:w-4/12"></div>
          </div>

          {usTopCategory.map(({ node }, i) => (
            <CategoryRoll node={node} key={i} />
          ))}
          {/* <TVRoll /> */}
          {/* <AdNonPremium /> */}
          {usBottomCategory.map(({ node }, i) => (
            <CategoryRoll node={node} key={i} />
          ))}
          {/* <AdPremium /> */}
        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = propTypes

export default IndexPage

export const pageQuery = graphql`
  query PageEnUsQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    usTopBreaking: allContentfulCategories(
      sort: { order: ASC, fields: categorySort }
      filter: {
        node_locale: { eq: "en-US" }
        categorySort: { gte: 1, lte: 3 }
        category: { eq: "Breaking News" }
      }
    ) {
      edges {
        node {
          id
          node_locale
          category
          categoryColor
          categorySlug
          categorySort
          posts {
            id
            node_locale
            slug
            postTitle
            postDate
            postDescription
            postImage {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            postAuthor {
              fullName
              position
            }
            postSubCategories {
              subCategories
            }
          }
        }
      }
    }
    breakingQuery: allContentfulPosts(
      filter: { node_locale: { eq: "en-US" }, postBreaking: { eq: true } }
      limit: 20
      sort: { order: DESC, fields: postDate }
    ) {
      edges {
        node {
          postTitle
          slug
          id
          postDate
          node_locale
          postBreaking
        }
      }
    }
    usTop: allContentfulCategories(
      sort: { order: ASC, fields: categorySort }
      filter: {
        node_locale: { eq: "en-US" }
        categorySort: { gte: 1, lte: 3 }
        category: { ne: "Breaking News" }
      }
    ) {
      edges {
        node {
          id
          node_locale
          category
          categoryColor
          categorySlug
          categorySort
          posts {
            id
            node_locale
            slug
            postTitle
            postDate
            postDescription
            postImage {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            postAuthor {
              fullName
              position
            }
            postSubCategories {
              subCategories
            }
          }
        }
      }
    }
    usBottom: allContentfulCategories(
      sort: { order: ASC, fields: categorySort }
      filter: { node_locale: { eq: "en-US" }, categorySort: { gte: 4, lte: 5 } }
    ) {
      edges {
        node {
          id
          node_locale
          category
          categoryColor
          categorySlug
          categorySort
          posts {
            id
            node_locale
            slug
            postTitle
            postDate
            postDescription
            postImage {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            postAuthor {
              fullName
              position
            }
            postSubCategories {
              subCategories
            }
          }
        }
      }
    }
  }
`
